<template>
  <b-container>
    <b-row>
      <b-col>
        <h1>
          {{ $t("Devices") }}
          <b-link :to="{ name: 'Options' }">
            <b-icon-arrow-return-left />
          </b-link>
        </h1>
        <b-breadcrumb :items="br_items" />
        <b-form-group label="Filter">
          <b-input-group>
            <b-input v-model="filter_str" />
            <template #append>
              <b-select v-model="filter_active" :options="active_options" />
              <b-button
                v-if="filter_str.length"
                variant="outline-danger"
                @click="filter_str = ''"
              >
                <b-icon-trash />
              </b-button>
            </template>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-table
          :items="items"
          :fields="fields"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <template v-slot:cell(active)="item">
            <b-button
              variant="primary"
              size="sm"
              v-if="!item.unformatted"
              @click="activate_item(item.item.uuid)"
              >{{ $t("activate") }}
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              v-else
              @click="activate_item(item.item.uuid, false)"
              >{{ $t("deactivate") }}
            </b-button>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="items.length"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { parseISO } from "date-fns";
import { orderBy } from "lodash-es";
import format from "date-fns/esm/format";
export default {
  name: "Devices",
  mounted() {
    this.$store.dispatch("load_data", ["device"]);
  },
  data() {
    return { currentPage: 1, perPage: 15, filter_str: "", filter_active: null };
  },
  computed: {
    active_options() {
      return [
        { text: this.$t("All"), value: null },
        { text: this.$t("Active"), value: true },
        { text: this.$t("Not active"), value: false },
      ];
    },
    br_items() {
      return [
        { text: this.$t("Home"), to: { name: "Home" } },
        { text: this.$t("Settings"), to: { name: "Options" } },
        { text: this.$t("Devices"), active: true },
      ];
    },
    items() {
      let ret = this.$store.getters["device"];
      if (this.filter_active !== null) {
        ret = ret.filter((d) => d.active == this.filter_active);
      }
      if (this.filter_str) {
        ret = ret.filter(
          (d) =>
            `${d.name} ${d.model} ${d.manufacturer} ${d.uuid}`
              .toLowerCase()
              .indexOf(this.filter_str.toLowerCase()) >= 0
        );
      }
      ret = orderBy(ret, ["pk"], ["desc"]);
      return ret;
    },
    fields() {
      return [
        {
          key: "id",
          label: "pk",
        },
        {
          label: this.$t("Name"),
          key: "name",
          formatter: (val, key, item) => item.alias || item.uuid,
        },

        { key: "model", label: this.$t("Model") },
        { key: "manufacturer", label: this.$t("Manufacturer") },
        { key: "version", label: this.$t("Version") },
        {
          key: "last_seen",
          label: this.$t("Last seen"),
          formatter: (v) => (v ? format(parseISO(v), "yyyy-MM-dd hh:mm") : ""),
        },
        "sdk",
        {
          label: this.$t("Status"),
          key: "active",
          class: "actions-cell",
          formatter: (val) => (val ? "✓" : "✗"),
        },
      ];
    },
  },
  methods: {
    activate_item(uuid, value = true) {
      let check = true;
      if (value === false) {
        check = confirm("Are you sure want deactivate device?");
      }
      if (check) {
        this.$store.dispatch("update_item", {
          type: "device",
          id: uuid,
          data: {
            active: value,
          },
        });
      }
    },
  },
};
</script>

<style>
td.actions-cell {
  padding: 0.5em 0 0;
}
</style>
